<template>
  <div class="course-by-userStudy">
    <table-list
      title="课程列表"
      :loading="loading"
      :data="courseWantList"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :operates="operates(this)"
      :options="{ fixScrollTable: true }"
      :pager="pager"
      @search="onSearch"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>
<script>
// 搜索框配置
const searchForm = () => [
  {
    label: '课程名称',
    prop: 'courseName',
  },
  {
    label: '课程类型',
    type: 'select',
    prop: 'courseType',
    children: [
      { value: 0, label: '录播' },
      { value: 1, label: '直播' },
    ],
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'coursePic',
    label: '课程封面',
    render: (h, { row }) => {
      return [
        row.coursePic
          ? h('img', {
              domProps: { src: row.coursePic },
              style: 'height: 50px',
            })
          : null,
      ]
    },
    minWidth: 150,
  },
  {
    prop: 'courseName',
    label: '课程名称',
    minWidth: 150,
  },
  {
    prop: 'courseType',
    label: '课程类型',
    formatter: row => {
      return { 0: '录播', 1: '直播', 9: '未知' }[row.courseType]
    },
    minWidth: 100,
  },
  {
    prop: 'teacherName',
    label: '任课老师',
    minWidth: 150,
  },
  {
    prop: 'sectionCount',
    label: '章节数',
    align: 'right',
    minWidth: 100,
  },
  {
    prop: 'courseMinuteDuration',
    label: '课程总时长（分钟）',
    formatter: row => {
      return row.courseMinuteDuration
    },
    align: 'right',
    minWidth: 170,
  },
  {
    prop: 'studentCount',
    label: '想学人数',
    sortable: 'sortable',
    minWidth: 100,
    align: 'right',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '详情',
    method: _this.handleDetail,
  },
]
import TableList from '@/components/TableList'
import { courseWantLearn } from '@/api/course'
import to from 'await-to'
export default {
  name: 'CourseByUserStudy',
  components: {
    TableList,
  },
  data() {
    return {
      columns,
      operates,
      searchForm,
      loading: false,
      courseWantList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
    }
  },
  mounted() {
    this.getCourseWant()
  },
  methods: {
    async getCourseWant() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(courseWantLearn({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.courseWantList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getCourseWant()
    },
    //详情
    handleDetail(row) {
      this.$router.push(`/course/shop/want/${row.productId}`)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getCourseWant()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getCourseWant()
    },
  },
}
</script>
