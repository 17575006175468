<template>
  <div class="Setting">
    <el-dialog
      title="购买记录"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      width="750px"
      class="Item-recordList"
      @close="cancel"
    >
      <div class="dialogBody">
        <table-list
          title="购买记录列表"
          :loading="loading"
          :data="courseRecordList"
          :search-form="searchForm(this)"
          :columns="columns(this)"
          @search="onSearch"
        ></table-list>
      </div>
    </el-dialog>
    <el-dialog
      title="退款申请"
      :close-on-click-modal="false"
      :visible.sync="dialogBackMoneyVisible"
      width="480px"
      class="Item-backmoney"
      @close="cancelbackMoneyUi"
    >
      <div class="mainPanel">
        <span class="item-name"
          >您购买的{{ courseName }}课程满足“7天无理由退货要求”，您确定要申请
          退款吗？确认后将不可撤销。</span
        >
        <div class="item-num">
          <div class="item-mk">
            <span class="item-one">应退款金额：</span>
            <span class="item-two">￥{{ moneyNum }}</span>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelbackMoneyUi">取 消</el-button>
        <el-button type="primary" @click="handleSureBackMoney">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// 搜索框配置
const searchForm = () => [
  {
    label: '课程状态',
    type: 'select',
    prop: 'courseStatus',
    children: [
      { value: 1, label: '已退款' },
      { value: 2, label: '已过期' },
      { value: 3, label: '生效中' },
    ],
  },
  {
    label: '购买时间',
    type: 'picker',
    prop: 'BuyTime',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'courseStatusExplain',
    label: '课程状态',
    minWidth: 60,
  },
  {
    prop: 'totalPurchases',
    label: '购买数量',
    align: 'right',
    minWidth: 60,
  },
  {
    prop: 'expireYears',
    label: '购买时长（年）',
    align: 'right',
    minWidth: 100,
  },
  {
    prop: 'remainStock',
    label: '可用数量',
    align: 'right',
    minWidth: 60,
  },
  {
    prop: 'leftTimeCount',
    label: '可用天数（天）',
    align: 'right',
    minWidth: 100,
  },
  {
    prop: 'buyTime',
    label: '购买时间',
    align: 'left',
    minWidth: 130,
  },
]
// 表格操作按钮配置
// const operates = _this => [
//   {
//     label: '退款',
//     isShow: row => row.refundFlag == 1,
//     method: _this.handleBackMoney,
//   },
// ]
import TableList from '@/components/TableList'
import { courseBuyrecord, backMoneyApply } from '@/api/course'
import to from 'await-to'
export default {
  name: 'DialogRecord',
  components: {
    TableList,
  },
  data() {
    return {
      dialogFormVisible: false,
      dialogBackMoneyVisible: false,
      loading: false,
      searchForm,
      columns,
      // operates,
      courseRecordList: [],
      productId: 0,
      queryParams: {},
      courseName: '',
      moneyNum: '',
      orderNo: '',
    }
  },
  methods: {
    show(row) {
      this.dialogFormVisible = true
      this.productId = row.productId
      this.courseName = row.name
      this.handleGetCourseRecordList(row.productId)
    },
    async handleGetCourseRecordList(id) {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(courseBuyrecord({ productId: id, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.courseRecordList = res.data
    },
    cancel() {
      this.dialogFormVisible = false
      this.courseRecordList = []
      this.productId = 0
    },
    //查询
    onSearch(queryParams) {
      if (queryParams.BuyTime) {
        queryParams.beginTime = queryParams.BuyTime[0]
        queryParams.endTime = queryParams.BuyTime[1]
      } else {
        queryParams.beginTime = ''
        queryParams.endTime = ''
      }
      this.queryParams = queryParams
      this.handleGetCourseRecordList(this.productId)
    },
    handleBackMoney(row) {
      this.dialogFormVisible = false
      this.dialogBackMoneyVisible = true
      this.moneyNum = row.orderAmount
      this.orderNo = row.orderNo
    },
    cancelbackMoneyUi() {
      this.dialogBackMoneyVisible = false
    },
    async handleSureBackMoney() {
      const [, err] = await to(backMoneyApply({ orderNo: this.orderNo, productId: this.productId }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('退款申请已提交，请耐心等待')
      this.dialogBackMoneyVisible = false
      this.dialogFormVisible = true
    },
  },
}
</script>
<style scoped lang="scss">
.Setting {
  .Item-recordList ::v-deep .el-dialog__header {
    border-bottom: 1px solid #d6d6d6;
  }
  .Item-recordList ::v-deep .el-dialog__body {
    padding: 20px 20px;
    .dialogBody {
      height: 450px;
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      overflow-x: hidden;
      overflow-y: scroll;
      margin-bottom: 20px;
      .table-search-bar {
        padding: 0;
        .el-form-item {
          margin-bottom: 10px;
        }
      }
      .top-bar {
        padding: 0 0;
      }
      .table-list {
        .el-table th {
          background: #eaeaea;
        }
        .table-search-bar {
          .el-form-item .el-date-editor {
            width: 230px !important;
          }
        }
      }
    }
    .dialogBody::-webkit-scrollbar {
      display: none;
    }
  }
  .Item-backmoney ::v-deep .el-dialog__header {
    border-bottom: 1px solid #d6d6d6;
  }
  .Item-backmoney ::v-deep .el-dialog__body {
    padding: 35px 25px;
    .item-name {
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #6b6f73;
    }
    .item-num {
      margin-top: 40px;
      position: relative;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #6b6f73;
      .item-mk {
        position: absolute;
        right: 0;
      }
      .item-one {
        color: #6b6f73;
      }
      .item-two {
        color: #ff4b36;
      }
    }
  }
}
</style>
