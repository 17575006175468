<!--
 * @Description: 书院课程库
 * @Author: xiawenlong
 * @Date: 2021-04-22 11:43:57
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-08-23 16:50:50
-->
<template>
  <div class="course-library">
    <el-tabs v-model="activeName">
      <el-tab-pane label="自建课程" name="1">
        <course-by-self v-if="activeName == 1"></course-by-self>
      </el-tab-pane>
      <el-tab-pane label="已购课程" name="2">
        <course-by-buy v-if="activeName == 2"></course-by-buy>
      </el-tab-pane>
      <el-tab-pane label="员工想学" name="3">
        <course-by-userStudy v-if="activeName == 3"></course-by-userStudy>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import CourseBySelf from './components/CourseBySelf'
import CourseByBuy from './components/CourseByBuy'
import CourseByUserStudy from './components/CourseByUserStudy'
export default {
  name: 'CourseLibrary',
  components: {
    CourseBySelf,
    CourseByBuy,
    CourseByUserStudy,
  },
  data() {
    return {
      activeName: '1',
    }
  },
}
</script>
<style lang="scss" scoped>
.course-library {
  ::v-deep.el-tabs {
    .el-tabs__header {
      font-size: 16px;
      color: #ff7b33;
      line-height: 16px;
      margin-bottom: 20px;
      .el-tabs__nav {
        height: 16px;
        padding-bottom: 7px;
        .el-tabs__item {
          height: 16px;
          line-height: 16px;
        }
      }
    }
  }
}
</style>
