<!--
 * @Description: 已购课程
 * @Author: xiawenlong
 * @Date: 2021-04-26 08:59:45
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-08-23 16:51:13
-->
<template>
  <div class="course-by-buy">
    <table-list
      title="课程列表"
      :loading="loading"
      :data="courseBuyList"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :btns="btns(this)"
      :operates="operates(this)"
      :options="{ operatesWidth: 180, fixScrollTable: true }"
      :pager="pager"
      @search="onSearch"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
    <dialogRecord ref="dialogRecord"></dialogRecord>
    <dialogUse ref="dialogUse"></dialogUse>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '课程商城',
    type: 'primary',
    method: _this.handleCourseShop,
  },
]
// 搜索框配置
const searchForm = () => [
  {
    label: '课程名称',
    prop: 'keyword',
  },
  {
    label: '课程类型',
    type: 'select',
    prop: 'courseType',
    children: [
      { value: 0, label: '录播' },
      { value: 1, label: '直播' },
    ],
  },
  {
    label: '购买方式',
    type: 'select',
    prop: 'buyMethod',
    children: [
      { value: 1, label: '按年买断不限量' },
      { value: 2, label: '按员工数量购买' },
    ],
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'pic',
    label: '课程封面',
    render: (h, { row }) => {
      return [
        row.pic
          ? h('img', {
              domProps: { src: row.pic },
              style: 'height: 50px',
            })
          : null,
      ]
    },
    minWidth: 100,
  },
  {
    prop: 'name',
    label: '课程名称',
    minWidth: 100,
  },
  {
    prop: 'courseType',
    label: '课程类型',
    formatter: row => {
      return { 0: '录播', 1: '直播', 9: '未知' }[row.courseType]
    },
    minWidth: 80,
  },
  {
    prop: 'teacherList',
    label: '任课老师',
    formatter: row => {
      if (row.teacherList.length) {
        let nameArray = row.teacherList.map(element => element.teacherName)
        return nameArray.join('，')
      } else {
        return ''
      }
    },
    minWidth: 100,
  },
  {
    prop: 'chapterCount',
    label: '章节数',
    sortable: 'sortable',
    align: 'right',
    minWidth: 100,
  },
  {
    prop: 'durationTotal',
    label: '课程总时长（分钟）',
    formatter: row => {
      return row.durationTotal
    },
    sortable: 'sortable',
    align: 'right',
    minWidth: 170,
  },
  {
    prop: 'buyMethod',
    label: '购买方式',
    sortable: 'sortable',
    align: 'left',
    formatter: row => {
      return { 1: '按包年不限量', 2: '按人数购买' }[row.buyMethod]
    },
    minWidth: 100,
  },
  {
    prop: 'remainStock',
    label: '可用数量',
    sortable: 'sortable',
    align: 'right',
    minWidth: 100,
  },
  {
    prop: 'availeDays',
    label: '可用天数',
    sortable: 'sortable',
    align: 'right',
    minWidth: 100,
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '购买记录',
    method: _this.handleBuyrecord,
  },
  {
    label: '使用记录',
    method: _this.handleUserecord,
  },
  {
    label: '详情',
    method: _this.handleDetail,
  },
]
import dialogRecord from '../components/records/dialogRecord'
import dialogUse from '../components/records/dialogUse'
import TableList from '@/components/TableList'
import { courseBuyList } from '@/api/course'
import to from 'await-to'
export default {
  name: 'CourseByBuy',
  components: {
    TableList,
    dialogRecord,
    dialogUse,
  },
  data() {
    return {
      searchForm,
      columns,
      btns,
      operates,
      loading: false,
      courseBuyList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
    }
  },
  mounted() {
    this.getCourseBuy()
  },
  methods: {
    async getCourseBuy() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(courseBuyList({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.courseBuyList = res.data.list
      this.pager.total = res.data.total
    },
    //课程商城按钮
    handleCourseShop() {
      this.$router.push('/course/shop')
    },
    //购买记录
    handleBuyrecord(row) {
      this.$refs.dialogRecord.show(row)
    },
    //使用记录
    handleUserecord(row) {
      this.$refs.dialogUse.show(row)
    },
    //详情
    handleDetail(row) {
      this.$router.push(`/course/shop/${row.productId}`)
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      if (queryParams.createTime) {
        queryParams.startTime = queryParams.createTime[0]
        queryParams.endTime = queryParams.createTime[1]
      } else {
        queryParams.startTime = ''
        queryParams.endTime = ''
      }
      this.queryParams = queryParams
      this.getCourseBuy()
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getCourseBuy()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getCourseBuy()
    },
  },
}
</script>
