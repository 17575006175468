<template>
  <div class="UseList">
    <el-dialog
      title="使用记录"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      width="750px"
      class="Item-UseList"
      @close="cancel"
    >
      <div class="dialogBody">
        <table-list
          title="使用记录列表"
          :loading="loading"
          :data="courseUseList"
          :search-form="searchForm(this)"
          :columns="columns(this)"
          @search="onSearch"
        ></table-list>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// 搜索框配置
const searchForm = () => [
  {
    label: '使用状态',
    type: 'select',
    prop: 'useType',
    children: [
      { value: 1, label: '班级' },
      { value: 2, label: '公开课' },
    ],
  },
  {
    label: '购买时间',
    type: 'picker',
    prop: 'BuyTime',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'useTypeExplain',
    label: '使用类型',
    minWidth: 100,
  },
  {
    prop: 'className',
    label: '使用班级名称',
    minWidth: 150,
  },
  {
    prop: 'usedStock',
    label: '使用数量',
    align: 'right',
    minWidth: 80,
  },
  {
    prop: 'beginTime',
    label: '使用时间',
    minWidth: 150,
  },
]
import TableList from '@/components/TableList'
import { courseUseList } from '@/api/course'
import to from 'await-to'
export default {
  name: 'DialogUse',
  components: {
    TableList,
  },
  data() {
    return {
      dialogFormVisible: false,
      loading: false,
      searchForm,
      columns,
      courseUseList: [],
      productId: 0,
      buyMethod: 0,
      queryParams: {},
    }
  },
  methods: {
    show(row) {
      this.dialogFormVisible = true
      this.productId = row.productId
      this.buyMethod = row.buyMethod
      this.handleGetCourseUseList(row.productId, row.buyMethod)
    },
    cancel() {
      this.dialogFormVisible = false
      this.courseUseList = []
      this.productId = 0
      this.buyMethod = 0
    },
    async handleGetCourseUseList(id, buyMethod) {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(
        courseUseList({ productId: id, buyMethod: buyMethod, ...this.queryParams }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.courseUseList = res.data
    },
    //查询
    onSearch(queryParams) {
      if (queryParams.BuyTime) {
        queryParams.beginTime = queryParams.BuyTime[0]
        queryParams.endTime = queryParams.BuyTime[1]
      } else {
        queryParams.beginTime = ''
        queryParams.endTime = ''
      }
      this.queryParams = queryParams
      this.handleGetCourseUseList(this.productId, this.buyMethod)
    },
  },
}
</script>
<style scoped lang="scss">
.UseList {
  .Item-UseList ::v-deep .el-dialog__header {
    border-bottom: 1px solid #d6d6d6;
  }
  .Item-UseList ::v-deep .el-dialog__body {
    padding: 20px 20px;
    .dialogBody {
      height: 450px;
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      overflow-x: hidden;
      overflow-y: scroll;
      margin-bottom: 20px;
      .table-search-bar {
        padding: 0;
        .el-form-item {
          margin-bottom: 10px;
        }
      }
      .top-bar {
        padding: 0 0;
      }
      .table-list {
        .el-table th {
          background: #eaeaea;
        }
        .table-search-bar {
          .el-form-item .el-date-editor {
            width: 230px !important;
          }
        }
      }
    }
    .dialogBody::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
